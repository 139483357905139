<template>
  <div class="news-list">
    <DirectusErrorView v-if="error" @retry="refresh" :status="status" />
    <p v-if="noNews"
       class="text-center">{{ $t('news.no_news') }}</p>
    <div class="news-list__grid">
      <TransitionGroup>
        <NewsArticlePreview v-for="article in articles"
                            :key="article.id"
                            :article="article" />
      </TransitionGroup>
    </div>
    <ButtonSecondary v-if="moreNewsAvailable"
                     class="sm:w-1/3"
                     @click="loadMore">{{
        $t('news.load_more')
      }}
    </ButtonSecondary>
  </div>
</template>
<script setup lang="ts">

const props = defineProps<{
  pageSize: number;
}>();

const { getItems } = useDirectusItems();
const moreNewsAvailable = useState(() => false);
const page = useState(() => 0);
const noNews = useState(() => false);
const locale = usePlatformSettings().locale;
const platformName = usePlatformSettings().platformName;
const countryCode = usePlatformSettings().countryCode;

const { data: articles, error, refresh, status } = await useAsyncData<NewsArticle[]>(async () => {
  const items = await loadNewsArticles();
  noNews.value = items.length === 0;
  moreNewsAvailable.value = items.length > props.pageSize * (page.value + 1);
  return items.slice(0, props.pageSize * (page.value + 1));
});

const loadMore = () => {
  page.value++;
  refresh();
};


function reloadNewsArticles() {
  page.value = 0;
  articles.value = [];
  refresh();
}

onMounted(() => {
  watch(locale, () => reloadNewsArticles());
});

async function loadNewsArticles() {
  return await getItems<NewsArticle>({
    collection: 'd_news_translations',
    params: {
      limit: (props.pageSize * (page.value + 1)) + 1,
      offset: 0,
      fields: ['*', 'd_news_id.*', 'd_news_id.status'],
      sort: '-d_news_id.date_created',
      filter: {
        languages_id: locale.value,
        d_news_id: {
          status: 'published',
          platform: {
            d_platform_id: {
              name: platformName.value
            }
          },
          country: {
            d_country_id: {
              code: countryCode.value
            }
          }
        }
      }
    }
  });
}
</script>

<style scoped lang="scss">
.news-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  &__grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(12, [col-start] 1fr);
    gap: 2rem;
    margin: 2rem 0;
  }

  @include bp-md {
    &__grid {
      gap: 1rem;
    }
  }
}

.v-enter-from {
  opacity: 0;
}

.v-enter-to {
  opacity: 1;
}

.v-enter-active {
  transition: opacity 0.2s;
}
</style>
